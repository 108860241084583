export const getUrlInStrings = (str: string): string => {
  // if value has mutliple strings in it, look if one is a url link and set it as the value
  // For example: <iframe width="560" height="315" src="https://www.youtube.com/embed/t0qQSujSslQ?si=MLS7fYR2VXKqdW7D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  // the urls would be https://www.youtube.com/embed/t0qQSujSslQ?si=MLS7fYR2VXKqdW7D
  const urls = str.match(/https:\/\/[^ "]+/g)
  if (urls && urls.length > 0) {
    return urls[0]
  } else return ''
}

export const extractIframeLinkFromString = (str: string): string => {
  const url = getUrlInStrings(str)
  if (url.includes('https://www.tiktok.com')) {
    // link will be in the format https://www.tiktok.com/@username/video/1234567891234567891
    // we need to change it to https://www.tiktok.com/embed/v2/1234567891234567891
    const parts = url.split('/')
    const videoId = parts[parts.length - 1]
    return `https://www.tiktok.com/embed/v2/${videoId}`
  }
  return url
}

/**
 * Capitalizes the first letter of a string
 * ref: https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
 * @param  {String} string
 * @return {String}   First letter of that string is capitalized
 */
export const capitalizeFirstLetter = (string: string): string => {
  if (string && typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1)
  } else {
    return string
  }
}

export const sanitizeUsername = (username: string): string => {
  let toReturn = ''
  // allows users to type emails without regard to casing
  toReturn = username.toLowerCase()
  // allows users to copy paste and not worry about empty space before, after, or even within username
  toReturn = toReturn.replace(' ', '')
  return toReturn
}

export const sanitizePassword = (password: string): string => password.replace(' ', '')

export const formatToPrice = (price: number): string =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price || 0)

export const centsToDollars = (cents: number | undefined | null): string =>
  cents !== undefined && cents !== null
    ? (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    : ''

export const transformWatchYoutubLinkToEmbed = (youtubeLink: string | undefined | null): string | undefined => {
  if (!youtubeLink) {
    return undefined
  }
  // replace in string "https://www.youtube.com/watch?v=11V358X7KGI&ab_channel=SherAliEDITS" with "https://www.youtube.com/embed/11V358X7KGI
  youtubeLink = youtubeLink.replace('watch?v=', 'embed/')
  // remove everything after &ab_channel=SherAliEDITS
  const indexOfAnd = youtubeLink.indexOf('&')
  if (indexOfAnd > 0) {
    youtubeLink = youtubeLink.substring(0, indexOfAnd)
  }
  return youtubeLink
}

export const removeDoubleLineBreaksBySingleLineBreaks = (data: string): string => {
  if (!data) return ''
  return data.replace(/(\n\n)/gm, '\n')
}

//remove all white space between beginning of string
export const removeWhiteSpaceAtBeginnigOfString = (data: string): string => {
  if (!data) return ''
  return data.replace(/^\s+/g, '')
}

export const removeQuotesAsFirstChar = (data: string): string => {
  if (!data) return ''
  return data.replace(/^"/g, '')
}
export const removeQuotesAsLastChar = (data: string): string => {
  if (!data) return ''
  return data.replace(/"$/g, '')
}

export const removeQuotesAtFirstOrLastChar = (data: string): string => {
  if (!data) return ''
  return removeQuotesAsLastChar(removeQuotesAsFirstChar(data))
}

export const makeAllRelativeLinksStartWithSlash = (data: string): string => {
  if (!data) return ''
  return data.replace(/(\[.*?\])\(([^)]+)\)/g, (match, text, url) => {
    // Check if the URL is already absolute
    if (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('/')) {
      return `${text}(${url})`
    } else {
      return `${text}(/${url})`
    }
  })
}
